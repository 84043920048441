import React from "react"
import { Navigate, Route } from "react-router-dom"
import BACalendar from "../calendars/BACalendar"
import CMCalendar from "../calendars/CMCalendar"
import { GameSelector } from "../calendars/GameSelector"
import SCCalendar from "../calendars/SCCalendar"
import { SentryRoutes } from "../index"
import RTCalendar from "../calendars/RTCalendar";
import TOHCalendar from "../calendars/TOHCalendar";

const AuthenticatedRouting = () => (
    <SentryRoutes>
        <Route exact path="*" element={<Navigate replace to="/select-game" />} />
        <Route exact path="/select-game" element={<GameSelector />} />
        <Route exact path="/cm" element={<CMCalendar />} />
        <Route exact path="/sc" element={<SCCalendar />} />
        <Route exact path="/ba" element={<BACalendar />} />
        <Route exact path="/rt" element={<RTCalendar />} />
        <Route exact path="/toh" element={<TOHCalendar />} />
    </SentryRoutes>
)

export default AuthenticatedRouting
