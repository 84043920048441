import React, {useEffect} from 'react';
import {Box, Button, Card, CardActionArea, CardActions, CardMedia} from "@mui/material";
import CM from "./images/CM.jpg"
import BA from "./images/BA.png"
import SC from "./images/SC.jpg"
import RT from "./images/RT.png"
import TOH from "./images/TOH.png"
import {useNavigate} from "react-router-dom";
import Navbar from "../elements/Navbar";
import {SELECTED_GAME} from "../constants";


export const GameSelector = () => {
    const navigate = useNavigate()
    function handleSelectGame(game) {
        if (game !== SELECTED_GAME.NO_GAME) {
            if (game === SELECTED_GAME.NO_GAME) {
                navigate(`/select-game`)
            } else {
                // window.localStorage.setItem("game", game)
                navigate(`/${game.toLowerCase()}`)
            }
        }

    }

    return <>
        <Navbar handleSelectGame={handleSelectGame}/>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "40px",
            height: "100%",
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px"
            }}>

                <Card sx={{width: 350, height: 280, mx: "10px"}}>
                    <CardActionArea onClick={() => handleSelectGame(SELECTED_GAME.CM)}>
                        <CardMedia
                            component="img"
                            height="230"
                            image={CM}
                            alt="Clockmaker"
                        />
                    </CardActionArea>
                    <CardActions sx={{justifyContent: "center"}}>
                        <Button size="small" color="primary" onClick={() => handleSelectGame(SELECTED_GAME.CM)}>
                            CLOCKMAKER CALENDAR
                        </Button>
                    </CardActions>
                </Card>
                <Card sx={{width: 350, height: 280, mx: "10px"}}>
                    <CardActionArea onClick={() => handleSelectGame(SELECTED_GAME.TOH)}>
                        <CardMedia
                            component="img"
                            height="230"
                            image={TOH}
                            alt="Train of Hope"
                        />
                    </CardActionArea>
                    <CardActions sx={{justifyContent: "center"}}>
                        <Button size="small" color="primary" onClick={() => handleSelectGame(SELECTED_GAME.TOH)}>
                            TRAIN OF HOPE CALENDAR
                        </Button>
                    </CardActions>
                </Card>
            </Box>

            <Box sx={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Card sx={{width: 250, height: 250, mx: "10px"}}>
                    <CardActionArea onClick={() => handleSelectGame(SELECTED_GAME.SC)}>
                        <CardMedia
                            component="img"
                            height="180"
                            image={SC}
                            alt="Solitaire Cruise"
                        />
                    </CardActionArea>
                    <CardActions sx={{justifyContent: "center"}}>
                        <Button size="small" color="primary" onClick={() => handleSelectGame(SELECTED_GAME.SC)}>
                            SOLITAIRE CRUISE Automatic calendar
                        </Button>
                    </CardActions>
                </Card>
                <Card sx={{width: 250, height: 250, mx: "10px"}}>
                    <CardActionArea onClick={() => handleSelectGame(SELECTED_GAME.BA)}>
                        <CardMedia
                            component="img"
                            height="180"
                            image={BA}
                            alt="Bermuda Adventure"
                        />
                    </CardActionArea>
                    <CardActions sx={{justifyContent: "center"}}>
                        <Button size="small" color="primary" onClick={() => handleSelectGame(SELECTED_GAME.BA)}>
                            BERMUDA Automatic calendar
                        </Button>
                    </CardActions>
                </Card>
                <Card sx={{width: 250, height: 250, mx: "10px"}}>
                    <CardActionArea onClick={() => handleSelectGame(SELECTED_GAME.RT)}>
                        <CardMedia
                            component="img"
                            height="180"
                            image={RT}
                            alt="Roger That"
                        />
                    </CardActionArea>
                    <CardActions sx={{justifyContent: "center"}}>
                        <Button size="small" color="primary" onClick={() => handleSelectGame(SELECTED_GAME.RT)}>
                            ROGER THAT Automatic calendar
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Box>
    </>
}