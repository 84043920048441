import React, {useState} from 'react';
import {AppBar, Box, Button, createTheme, Grid, IconButton, ThemeProvider, Toolbar, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {SyncTasksStatus} from "./SyncTasksStatus";
import {EVENT_TYPE, SELECTED_GAME, SYNC_ASANA_STATUS, USER_ROLE} from "../constants";
import {isExists} from "../utils";
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import {SyncPopup} from "./SyncPopup";
import {getConfigByGame} from "../redux/userReducer";
import {indigo} from "@mui/material/colors";
import {LOG_OUT} from "../redux/types";
import LogoutIcon from '@mui/icons-material/Logout';
import {logoutUser} from "../redux/actions";
import HelpIcon from '@mui/icons-material/Help';
import SyncTemplatePopup from "../components/SyncTemplatePopup";
import {OneYearCopyPopup} from "./OneYearCopyPopup";
import {LogsDialog} from "./LogsDialog";

export default function Navbar({handleSelectGame, game, handleSelectedItems, groupPushDisabled}) {
    const asanaSyncInfo = useSelector((state) => state.asanaSyncInfo)
    const user = useSelector((state) => state.user.currentUser);
    const dispatch = useDispatch();

    const [popupSyncToAsana, setPopupSyncToAsana] = useState(false);
    const [logsDialogOpen, setLogsDialogOpen] = useState(false);

    const [popupSyncFromAsana, setPopupSyncFromAsana] = useState(false);
    const [popupSyncTemplate, setPopupSyncTemplate] = useState(false);
    const [oneYearCopyPopupOpen, setOneYearCopyPopup] = useState(false);

    const BootstrapTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} arrow classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            maxWidth: 220,
        },
    }));

    const logout = () => {
        dispatch(logoutUser())
        dispatch({type: LOG_OUT})
    }

    const handleOpenPopupSyncToAsana = (open) => {

        setPopupSyncToAsana(open);
    };

    const handleOpenLogs = (open) => {
        setLogsDialogOpen(true);
    };

    const handleCloseLogsDialog = () => {
        setLogsDialogOpen(false);
    };
    const handleOpenPopupSyncFromAsana = (open) => {
        setPopupSyncFromAsana(open);
    };

    function syncEventsToAsana() {
        handleOpenPopupSyncToAsana(false)
        fetch(`${game.toLowerCase()}/sync-events`,
            {
                method: 'POST',
            }
        )
            .then((response) => {
            })
            .catch((error) => {
            });
    }

    function syncEventsFromAsana() {
        handleOpenPopupSyncFromAsana(false)
        const params = {
            type: EVENT_TYPE.EVENT
        }
        fetch(`${game.toLowerCase()}/sync-events-from-asana?${new URLSearchParams(params).toString()}`,
            {
                method: 'POST',
            }
        )
            .then((response) => {
            })
            .catch((error) => {
            });
    }

    function handleClickLogo() {
        handleSelectGame(SELECTED_GAME.NO_GAME)
    }

    function getGameTitle() {
        if (game && game !== SELECTED_GAME.NO_GAME) {
            let title = ": ";
            if (game === SELECTED_GAME.CM) {
                title += "Clockmaker"
            } else if (game === SELECTED_GAME.SC) {
                title += "Solitaire Cruise"
            } else if (game === SELECTED_GAME.BA) {
                title += "Bermuda Adventure"
            } else if (game === SELECTED_GAME.RT) {
                title += "Roger That"
            } else if (game === SELECTED_GAME.TOH) {
                title += "Train of Hope"
            }
            return title
        }
    }

    function hasActiveSync() {
        return [asanaSyncInfo.syncToAsanaStatus, asanaSyncInfo.syncFromAsanaStatus].includes(SYNC_ASANA_STATUS.IN_SYNC);
    }
    const themeStatusButton = createTheme({
        palette: {
            primary: {
                main: indigo[900],
            }
        },
    });

    const logoutContainer =
            <Box display={"flex"} justifyContent={'end'}>
                <ThemeProvider theme={themeStatusButton}>
                    <Button
                        style={{minHeight: '36.5px'}}
                        startIcon={<LogoutIcon/>}
                        color={'warning'}
                        variant={'contained'}
                        onClick={logout}>
                    </Button>
                </ThemeProvider>
            </Box>

    const isViewer = isExists(game) && getConfigByGame(user, game).role === USER_ROLE.VIEWER;

    return <AppBar position="static">
        <Toolbar sx={{textAlign: "center", display: "grid", gridTemplateColumns: isViewer ? '1fr 3fr 1fr' : '2fr 1fr 2fr'}}>
            <div className="navbar__logo" onClick={handleClickLogo}/>
            <Typography variant="h6" component="div" sx={{textAlign: "center"}}>
                Belka Calendar{getGameTitle()}
                {(isViewer) && <>
                    .&nbsp;Viewer mode.
                    <Tooltip title="Connect to your system administrator to get editor rights" sx = {{bottom: '2px'}}>
                        <IconButton>
                            <HelpIcon sx={{ color: '#ed6c02' }}/>
                        </IconButton>
                    </Tooltip>
                </>}
            </Typography>
            {(isExists(game) && getConfigByGame(user, game).role === USER_ROLE.EDITOR) &&
                <Box sx={{display: "flex", justifyContent: "end", alignItems: "center"}}>

                    <Grid container spacing={1} justifyContent={'end'} alignItems={'end'}>

                        <Grid item>
                            <Box sx={{display: "flex", alignItems: "baseline", flexFlow: "column-reverse"}}>
                                <BootstrapTooltip title={"Просмотр журнал действий с событиями"}>
                            <span>
                                <IconButton
                                            onClick={handleOpenLogs} sx={{p: 0}}>
                                    <Box className="navbar__logs_button"/>
                                </IconButton>
                            </span>
                                </BootstrapTooltip>
                                <LogsDialog game={game}
                                           isOpen={logsDialogOpen}
                                           onClose={handleCloseLogsDialog}/>
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box sx={{display: "flex", alignItems: "baseline", flexFlow: "column-reverse"}}>
                                <BootstrapTooltip title={hasActiveSync() ? "Есть активная синхронизация" : ""}>
                            <span>
                                <IconButton disabled={hasActiveSync()} onClick={handleOpenPopupSyncToAsana} sx={{p: 0}}>
                                    <Box className="navbar__calendar_button"/>
                                </IconButton>
                            </span>
                                </BootstrapTooltip>
                                <SyncPopup message={"Вы собираетесь отправить в асану все несинхронизованные события"}
                                           syncEvents={syncEventsToAsana}
                                           isOpenSyncPopup={popupSyncToAsana}
                                           handleSyncPopup={setPopupSyncToAsana}/>
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box sx={{
                                display: (game === 'CM' || game === 'BA' || game === 'RT') ? "none" : "flex",
                                alignItems: "baseline",
                                flexFlow: "column-reverse",
                                pr: "24px"
                            }}>
                                <BootstrapTooltip
                                    title={hasActiveSync() ? "Есть активная синхронизация" : ""}>
                            <span>
                                <IconButton disabled={hasActiveSync()}
                                            onClick={() => handleOpenPopupSyncFromAsana(true)} sx={{p: 0}}>
                                    <Box className="navbar__asana_button"/>
                                </IconButton>
                            </span>
                                </BootstrapTooltip>
                                <SyncPopup message={"Подтвердите синхронизацию из Асаны"}
                                           syncEvents={syncEventsFromAsana}
                                           isOpenSyncPopup={popupSyncFromAsana}
                                           handleSyncPopup={setPopupSyncFromAsana}/>
                                <SyncTemplatePopup
                                    popupSyncTemplate={popupSyncTemplate}
                                    setPopupSyncTemplate={setPopupSyncTemplate}
                                />
                            </Box>
                        </Grid>
                        {[SELECTED_GAME.SC, SELECTED_GAME.BA].includes(game) &&<Grid item>
                            <ThemeProvider theme={themeStatusButton}>
                                <Button
                                    variant={'contained'}
                                    onClick={() => setOneYearCopyPopup(true)}>
                                    Mass copy
                                </Button>
                            </ThemeProvider>
                        </Grid>}
                        {game === SELECTED_GAME.CM && <Grid item>
                            <ThemeProvider theme={themeStatusButton}>
                                <Button
                                    disabled={groupPushDisabled}
                                    variant={'contained'}
                                    onClick={handleSelectedItems}>
                                    Group push
                                </Button>
                            </ThemeProvider>
                        </Grid>}
                        {game === SELECTED_GAME.BA && <Grid item>
                            <ThemeProvider theme={themeStatusButton}>
                                <Button
                                    variant={'contained'}
                                    onClick={() => setPopupSyncTemplate(true)}>
                                    Sync template
                                </Button>
                            </ThemeProvider>
                        </Grid>}
                        <Grid item>
                            <SyncTasksStatus/>
                        </Grid>
                        <Grid item>
                            {logoutContainer}
                        </Grid>
                    </Grid>
                    <OneYearCopyPopup game={game.toLowerCase()} status={oneYearCopyPopupOpen} setStatus={setOneYearCopyPopup}/>

                </Box>}
            {(!isExists(game) || getConfigByGame(user, game).role === USER_ROLE.VIEWER) && logoutContainer}
        </Toolbar>
    </AppBar>
}